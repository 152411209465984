import DataTableV2, { TableColumn } from '@/components/datatable/DataTable';
import Filter from '@/components/filters/Filter';
import { approveMembership, useGetMemberList } from '@/core/hooks/auth';
import { countries } from '@/utils/constants';
import formatDateToLongForm from '@/utils/DateFormattter';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const MemberList: React.FC = () => {
    const [status, setStatus] = useState('');
    const [residence_country, setCountry] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;
    const { loading, members, error, refetch }: any = useGetMemberList(
        pageSize,
        pageNumber,
        residence_country,
        search,
        status
    );

    useEffect(() => {
        refetch();
    }, [residence_country, status, pageNumber, pageSize]);

    const handleStatusChange = (e: any) => {
        setStatus(e.target.value);
    };

    const getStatusBadge = (status: string) => {
        let badgeClass = '';
        switch (status) {
            case 'pending':
                badgeClass = 'badge bg-warning';
                break;
            case 'approved':
                badgeClass = 'badge bg-success';
                break;
            case 'rejected':
                badgeClass = 'badge bg-danger';
                break;
            default:
                badgeClass = 'badge bg-secondary';
        }
        return <span className={badgeClass}>{status}</span>;
    };

    const handleApprove = async (row: any) => {
        try {
            const payload = { id: row.id, status: 'approved' };
            await approveMembership(payload);
            await refetch();
        } catch (error) {
            console.error('Error approving member:', error);
        }
    };

    const handleReject = async (row: any) => {
        try {
            const payload = { id: row.id, status: 'rejected' };
            await approveMembership(payload);
            await refetch();
        } catch (error) {
            console.error('Error rejecting member:', error);
        }
    };

    const columns: TableColumn<any>[] = [
        {
            title: 'Name',
            accessor: 'name',
            render: (row: any) => <p>{row.name}</p>,
        },
        {
            title: 'Gender',
            accessor: 'gender',
            render: (row: any) => <p>{row.gender}</p>,
        },
        {
            title: 'Identification Type',
            accessor: 'identification_type',
            render: (row: any) => (
                <p className="uppercase">{row.identification_type}</p>
            ),
        },
        {
            title: 'NID/Passport ID',
            accessor: 'nid',
            render: (row: any) => <p>{row.nid}</p>,
        },
        {
            title: 'Date of Birth',
            accessor: 'dateOfBirth',
            render: (row: any) => (
                <p>{formatDateToLongForm(row.dateOfBirth)}</p>
            ),
        },
        {
            title: 'Email',
            accessor: 'email',
            render: (row: any) => <p>{row.email}</p>,
        },
        {
            title: 'Phone Number',
            accessor: 'phoneNumber',
            render: (row: any) => <p>{row.phoneNumber}</p>,
        },
        {
            title: 'Residence Country',
            accessor: 'residence_country',
            render: (row: any) => (
                <p className="capitalize">{row.residence_country}</p>
            ),
        },
        {
            title: 'Status',
            accessor: 'status',
            render: (row: any) => getStatusBadge(row.status),
        },
        {
            title: 'Created At',
            accessor: 'created_at',
            render: (row: any) => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            render: (row: any) => (
                <p className="flex flex-row gap-2">
                    <button
                        className="btn btn-sm btn-primary"
                        onClick={() => handleApprove(row)}
                    >
                        Approve
                    </button>
                    <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleReject(row)}
                    >
                        Reject
                    </button>
                </p>
            ),
        },
    ];

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="mb-2 mx-auto ">
            <ol className="flex text-primary font-semibold dark:text-white-dark mb-2 ">
                <Link
                    to="/account/admin"
                    className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]"
                >
                    <button className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                        Home
                    </button>
                </Link>

                <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                    <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                        Members
                    </button>
                </li>
            </ol>
            <div>
                <DataTableV2
                    columns={columns}
                    data={members?.list ?? []}
                    total={members?.total ?? 0}
                    currentPage={members?.currentPage ?? 1}
                    filterComponent={
                        <Filter
                            resetFilters={() => {
                                setStatus('');
                                setCountry('');
                            }}
                            title="Filter Contributions"
                        >
                            <div className="mb-4">
                                <label htmlFor="countryCode">
                                    Country name:
                                </label>
                                <select
                                    id="countryCode"
                                    
                                    onChange={e => setCountry(e.target.value)}
                                    className="form-select"
                                >
                                    <option value="" disabled>
                                        Select a country
                                    </option>
                                    {countries.map(
                                        (country: any, index: number) => (
                                            <option
                                                key={index}
                                                value={country.name.toLowerCase()}
                                            >
                                                {country.name}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="status">
                                    Filter by Status:
                                </label>
                                <select
                                    id="status"
                                    value={status}
                                    onChange={handleStatusChange}
                                    className="form-input"
                                >
                                    <option value="">All</option>
                                    <option value="pending">Pending</option>
                                    <option value="approved">Approved</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                            </div>
                        </Filter>
                    }
                    tableName="Individual Contributions"
                    nextPage={members?.nextPage ?? 0}
                    previousPage={members?.previousPage ?? 0}
                    lastPage={members?.lastPage ?? 0}
                    isLoading={loading}
                />
            </div>
        </div>
    );
};

export default MemberList;
