import config from '@/config';
import { storage } from '@/utils';

import axios from 'axios';


const PRIVATE_API = axios.create({
    baseURL: `${config.API_URL}/api/v1/`,
    timeout: 120000,
    headers: {
        Authorization: `Bearer ${storage.getToken()}`
    }
});
export const PRIVATE_API_v2 = axios.create({
    baseURL: `${config.API_URL}/api/v2/`,
    timeout: 120000,
    headers: {
        Authorization: `Bearer ${storage.getToken()}`
    }
});

export default PRIVATE_API;
