import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import { Navigate } from 'react-router-dom';

export const RoleProtector = ({
    element,
    role,
}: {
    element: JSX.Element;
    role: string;
}): JSX.Element => {
    const token = storage.getToken();

    const { userAccount: user }: any = useAppSelector(store => store.user);

    if (!user || user === null) return <Navigate to={'/login'} />;

    if (!token) return <Navigate to={'/login'} />;

    if (user?.role !== role) return <Navigate to={'/login'} />;

    return element;
};
