
import { useAppDispatch } from '@/store';
import { addUser, removeUser } from '@/store/slices/userslice';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { get_users, get_applicants, user_profile, edit_user, register_user, admin_create_user, delete_user, activate_user, request_reset, Confirm_reset, LoginUserPayload, login_user } from '@/core/api/main';
import { PRIVATE_API_v2 } from '@/core/api/axios';
import fireToast from '@/components/toast';
import { appLogin } from '@/core/store/reducers/auth';
import { ApiResponse, UserDetails } from '@/core';
import { AuthLoginPayload, PaginationType } from '@/core/shared';

import { storage } from '@/utils';
import { error } from 'console';

export const useGetAllUsers = () => {
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [users, setUsers]: any = useState<PaginationType<UserDetails> | null>();
    const handleGetUsers = async (query?: string, search?: string) => {
        try {
            setLoadingUsers(true);
            const data: PaginationType<UserDetails> = await get_users(query);
            setUsers(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingUsers(false);
        }
    };

    return {
        loadingUsers,
        users,
        handleGetUsers,
    };
};

export const useGetAllApplicants = () => {
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [applicants, setApplicant]: any =
        useState<PaginationType<UserDetails> | null>();
    const handleApplicants = async (query?: string, search?: string) => {
        try {
            setLoadingUsers(true);
            const data: PaginationType<UserDetails> = await get_applicants(query);
            setApplicant(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingUsers(false);
        }
    };

    return {
        loadingUsers,
        applicants,
        handleApplicants,
    };
};
export const getMyProfile = () => {
    const [loadingPrifile, setLoadingProfile] = useState(false);
    const [profile, setProfile]: any =
        useState<PaginationType<UserDetails> | null>();
    const handleGetProfile = async (query?: string, search?: string) => {
        try {
            setLoadingProfile(true);
            const data: PaginationType<UserDetails> = await user_profile();
            setProfile(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingProfile(false);
        }
    };

    return {
        loadingPrifile,
        profile,
        handleGetProfile,
    };
};
export const EditUser = () => {
    const [loadingEditUser, setLoadingEditEvent] = useState(false);
    const hanldeEditUser = async (payload: UserDetails, id: string) => {
        try {
            setLoadingEditEvent(true);
            edit_user(payload, id).then(() => toast.success('User updated'));
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingEditEvent(false);
        }
    };

    return {
        hanldeEditUser,
        loadingEditUser,
    };
};

export const useLoginUser = () => {
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const handleLogin = async (payload: AuthLoginPayload) => {
        try {
            setLoadingLogin(true);
            setError(null);
            const response = await login_user(payload);
            const { access_token, user } = await response;
            storage.setToken(access_token);
       
            dispatch(addUser(user));
            setError(null);

            window.location.href = `/account/${user?.role}`;
        } catch (error: any) {
            setError(error.response.message);
        } finally {
            setLoadingLogin(false);
        }
    };

    return {
        loadingLogin,
        handleLogin,
        error,
    };
};
export const useCreateUser = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);
    const handleCreate = async (payload: UserDetails) => {
        try {
            setLoadingCreate(true);
            register_user(payload).then(() => toast.success('User Created'));
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreate,
    };
};

export const AdminCreateUser = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);
    const navigate = useNavigate();
    const handleCreate = async (payload: UserDetails) => {
        try {
            setLoadingCreate(true);
            const { res } = await admin_create_user(payload);
        toast.success('User Created');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreate,
    };
};

export const SignUp = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleCreate = async (payload: UserDetails) => {
        try {
            setLoadingCreate(true);
            const response = await register_user(payload);
            const { access_token, user } = await response;
            storage.setToken(access_token);
            console.log(user);
            dispatch(addUser(user));

            navigate('/user/activate/account');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreate,
    };
};

export const useDeleteUser = () => {
    const [loadingDelete, setLoadingDelete] = useState(false);
    const handleDelete = async (id: string) => {
        try {
            setLoadingDelete(true);
            await delete_user(id);
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingDelete(false);
        }
    };

    return {
        loadingDelete,
        handleDelete,
    };
};

export const useActivateUser = () => {
    const [loadingActivate, setLoadingActivate] = useState(false);
    const navigate = useNavigate();
    const handleActivate = async (activation_code: string) => {
        try {
            setLoadingActivate(true);
            await activate_user(activation_code);
            toast.success('Your Account Has been Activated');
            navigate('/login');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingActivate(false);
        }
    };

    return {
        loadingActivate,
        handleActivate,
    };
};

export const RequestOtp = () => {
    const [loadingReset, setLoadingReset] = useState(false);
    const navigate = useNavigate();
    const handleReset = async (payload: UserDetails) => {
        try {
            setLoadingReset(true);
            await request_reset(payload);

            navigate('/confirm_reset');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingReset(false);
        }
    };

    return {
        loadingReset,
        handleReset,
    };
};

export const ResetPassword = () => {
    const [loadingReset, setLoadingReset] = useState(false);
    const navigate = useNavigate();
    const handleReset = async (payload: UserDetails) => {
        try {
            setLoadingReset(true);
            await Confirm_reset(payload);
            toast.success('Password Reset ');
            navigate('/login');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingReset(false);
        }
    };

    return {
        loadingReset,
        handleReset,
    };
};

export const useLogout = () => {
    const [loadingLogout, setLoadingLogout] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleLogout = () => {
        try {
            setLoadingLogout(true);
            storage.removeToken();
            dispatch(removeUser());
            window.location.href = '/login';
        } catch (error) {
            toast.error('Logout Failed');
        } finally {
            setLoadingLogout(false);
        }
    };

    return {
        loadingLogout,
        handleLogout,
    };
};



export const useLocalLogin = () => {
    const [loading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
  
    const login = async (payload : { username: string, password: string }) => {
      try {
        setIsLoading(true);
        const response = await PRIVATE_API_v2.post('/login', payload);
        const data :  { token: string, memberId: string } = response.data;
        const memberId = data.memberId;
        let token = data.token;
        localStorage.setItem('userToken', token);
        localStorage.setItem('userId', memberId);
        dispatch(appLogin());
        fireToast({ message: 'Logged In Successfully', type: 'SUCCESS' });
      } catch (e : any) {
        console.log(e.response.data.message)
        const error = e.response.data.message ? e.response.data.message : null;
        fireToast({ message: error ?? 'An unexpected error occurred', type: 'FAILED' });
      } finally {
        setIsLoading(false);
      }
    }
  
    return {
      loading,
      login
    }
  }
  
  
  export const useGetUserData = (id: string) => {
    const [loading, setIsLoading] = useState(false);
    const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  
    useEffect(() => {
      const fetchUserDetails = async () => {
        try {
          setIsLoading(true);
          const response = await PRIVATE_API_v2.get(`users/${id}`);
          const data: ApiResponse<UserDetails> = response.data;
          setUserDetails(data.result);
        } catch (error : any ) {
        
          const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
          console.log(errorMessage);
        } finally {
          setIsLoading(false);
        }
      };
  
      if(id && id !== '') {
        fetchUserDetails();
      }
    }, [id]);
  
    return {
      loading,
      userDetails
    };
  };
  
  

  export const useGetMemberList = (
    pageSize: number,
    pageNumber: number,
    residence_country?:string,
    search?: string,
    status?: string
  ) => {
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState<UserDetails[]>([]);
    const [error, setError] = useState<string | null>(null);
  
    const fetchMembers = useCallback(async () => {
      setLoading(true);
      try {
        const response: any = await PRIVATE_API_v2.get("/members", {
          params: {
            pageSize,
            pageNumber,
            residence_country,
            search,
            status,
          },
        });
        setMembers(response?.data);
      } catch (error: any) {
        setError(
          error.response?.data?.message || "An unexpected error occurred"
        );
      } finally {
        setLoading(false);
      }
    }, [pageSize, pageNumber, search, status,residence_country]);
  
    useEffect(() => {
      fetchMembers();
    }, [fetchMembers]);
  
    return { loading, members, error, refetch: fetchMembers };
  };
  
  
  
  export const approveMembership = async (payload: any) => {
    try {
      const response = await PRIVATE_API_v2.post('/members/change-status', payload);
      console.log(response);
      toast.success("Member status changed!");
      return response;
    } catch (error: any) {
      console.error("Error approving member:", error);
      toast.error(
        error.response?.data?.message || 'An unexpected error occurred'
      );
      throw error;
    }
  };
  
  export const useGetContributions = (
    pageSize?: number,
    pageNumber?: number,
    phoneNumber?: string,
    contributionType?: number,
    email?: string,
    search?: string ,
    paymentMode?:string,
    currency?:string
  ) => {
    const [loading, setLoading] = useState(false);
    const [contributions, setContributions] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [total, setTotal] = useState(0);
  
    useEffect(() => {
      const fetchContributions = async () => {
        setLoading(true);
        try {
          const params: any = {};
          if (pageSize) params.pageSize = pageSize;
          if (pageNumber) params.pageNumber = pageNumber;
          if (phoneNumber) params.phoneNumber = phoneNumber;
          if (contributionType) params.contributionType = contributionType;
          if (email) params.email = email;
          if (search) params.search = search;
          if (paymentMode) params.paymentMode = paymentMode;
          if (currency) params.currency = currency;
          const response = await PRIVATE_API_v2.get("/contributions", {
            params,
          });
  
          setContributions(response?.data || []);
          setTotal(response?.data?.total || 0);
        } catch (error: any) {
          setError(
            error.response?.data?.message || "An unexpected error occurred"
          );
        } finally {
          setLoading(false);
        }
      };
  
      fetchContributions();
    }, [pageSize, pageNumber, contributionType, phoneNumber, email, search,paymentMode]); 
  
    return { loading, contributions, error, total };
  };
  
  
  