import Login from "@/app/auth/login";
import { Settings } from "@/app/dashboard/adminProfile";
import { ContributionsListIndividual } from "@/app/dashboard/contributions";
import { ContributionsListCompany } from "@/app/dashboard/contributions/campany";
import { CARDRWF } from "@/app/dashboard/contributions/more/card_RWF";
import { CardUSD } from "@/app/dashboard/contributions/more/card_USD";
import { MOMOTransactions } from "@/app/dashboard/contributions/more/momo";
import { SuccessfulTransactions } from "@/app/dashboard/contributions/more/successful";
import AdminDashboard from "@/app/dashboard/dashboardmain";
import MemberList from "@/app/dashboard/members";
import CountryReports from "@/app/dashboard/stats/country";
import DistrictReports from "@/app/dashboard/stats/district";
import DashUsers from "@/app/dashboard/users";
import CreateUser from "@/app/dashboard/users/create";
import Error404 from "@/app/errors/4004Error";
import AdminProfile from "@/app/layouts/admin-profile";
import UsersLayout from "@/app/layouts/user";
import AdminLayout from "@/components/layout/admin/DefaultLayout";
import { RoleProtector } from "@/core/protector";
import { useRoutes, Navigate } from "react-router-dom";

export default function AppRoutes() {
    return useRoutes([
        { path: '/', element: <Navigate to="/login" replace /> },
        { path: 'login', element: <Login /> },
        { path: '*', element: <Error404 /> },
        {
            path: 'account',
            children: [
                {
                    path: 'admin',
                    element: <RoleProtector role="admin" element={<AdminLayout />} />,
                    children: [
                        {
                            index: true,
                            element: <AdminDashboard />,
                        },

                         
                        {
                            path: 'reports/successful',
                            element: <SuccessfulTransactions />,
                        },
                        {
                            path: 'reports/momo',
                            element: <MOMOTransactions />,
                        },
                        {
                            path: 'reports/card-USD',
                            element: <CardUSD />,
                        },
                        {
                            path: 'reports/card-RWF',
                            element: <CARDRWF />,
                        },
                        {
                            path: 'members',
                            element: <MemberList />,
                        },
                        {
                            path: 'contributions',
                            element: <UsersLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <ContributionsListIndividual />,
                                },
                                {
                                    path: 'company',
                                    element: <ContributionsListCompany />,
                                },
                            ],
                        },
                        {
                            path: 'reports',
                            element: <UsersLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <DistrictReports />,
                                },
                                {
                                    path: 'country',
                                    element: <CountryReports />,
                                },
                            ],
                        },
                        {
                            path: 'users',
                            element: <UsersLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <DashUsers />,
                                },
                                {
                                    path: 'create',
                                    element: <CreateUser />,
                                },
                            ],
                        },
                        {
                            path: 'profile',
                            element: <AdminProfile />,
                            children: [
                                {
                                    index: true,
                                    element: <Settings />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ]);
}
