import { useState, useCallback, useEffect } from "react";
import { PRIVATE_API_v2 } from "@/core/api/axios";

interface DistrictReport {
  locationId: string;
  locationCode: string;
  locationLevel: number;
  locationName: string;
  totalAmount: string;
  count: string;
}

interface ApiResponse<T> {
  result: T;
}

  interface CountryReport {
    residence_country: string;
    amount: {
      USD: number;
    };
    count: number;
  }
  
  
  export const useGetCountryReport = () => {
    const [loading, setLoading] = useState(false);
    const [countryReport, setCountryReport] = useState<CountryReport[] | any>(null);
  
    const fetchCountryReport = async (country?: string, fromDate?: string, toDate?: string) => {
      try {
        setLoading(true);
        const params: any = {};
        if (country) params.country = country;
        if (fromDate) params.fromDate = fromDate;
        if (toDate) params.toDate = toDate;
        
        const response = await PRIVATE_API_v2.get(`/report/country/`, {
          params
        });
        
        const data: any = response.data;
        setCountryReport(data);
      } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
        console.error(errorMessage);
      } finally {
        setLoading(false);
      }
    };
  
    return { loading, countryReport, fetchCountryReport };
  };
  
  export const useGetDistrictReport = () => {
    const [loading, setLoading] = useState(false);
    const [districtReport, setDistrictReport] = useState<DistrictReport[] | null>(null);
  
    const fetchDistrictReport = useCallback(async (locationId?: string, fromDate?: string, toDate?: string) => {
      try {
        setLoading(true);
        const params: any = {};
        if (locationId) params.locationId = locationId;
        if (fromDate) params.fromDate = fromDate;
        if (toDate) params.toDate = toDate;
        
        const response = await PRIVATE_API_v2.get(`/report/district`, {
          params
        });
        
        const data: any = response.data;
        setDistrictReport(data);
      } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
        console.error(errorMessage);
      } finally {
        setLoading(false);
      }
    }, []);
  
    return { loading, districtReport, fetchDistrictReport };
  };
  export const useGetStatsReport = () => {
    const [loading, setLoading] = useState(false);
    const [statsReport, setStatsReport] = useState<[] | null>(null);
  
    const fetchStatsReport = useCallback(async () => {
      try {
        setLoading(true);
       
        const response = await PRIVATE_API_v2.get(`/report/stats`);
        
        const data: any = response.data;
        setStatsReport(data);
      } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
        console.error(errorMessage);
      } finally {
        setLoading(false);
      }
    }, []);
  
    return { loading, statsReport, fetchStatsReport };
  };
  

  export const useGetLocationsByLevelId = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [locations, setLocations] = useState<Location[] | null>(null);
    const [error, setError] = useState<string | null>(null);
  
    const levelId =2
      const fetchLocationsByLevelId = async () => {
        setLoading(true);
        try {
          const response = await PRIVATE_API_v2.get(`/location/${levelId}`, {
            
          });
          setLocations(response.data);
        } catch (error) {
          setError('Failed to fetch locations');
          console.error('Failed to fetch locations:', error);
        } finally {
          setLoading(false);
        }
      };
  
    
    

  
    return { loading, locations, error, fetchLocationsByLevelId };
  };