import z from 'zod';
import { useNavigate } from 'react-router-dom';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import CircularProgress from '@mui/material/CircularProgress';
import { useLoginUser } from '@/core/hooks/auth';
import { useEffect } from 'react';
import { addUser } from '@/store/slices/userslice';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { check_user, LoginUserPayload } from '@/core/api/main';
import { storage } from '@/utils';

const schema: any = z.object({
    username: z
        .string()
        .min(1, ' User Name required')
        .refine(value => value.trim() === value, {
            message: 'username cannot have leading or trailing spaces',
        }),
    password: z.string().min(1, 'Password required'),
});

export type LoginInput = z.TypeOf<typeof schema>;

export const Login = () => {
    const { loadingLogin, handleLogin, error } = useLoginUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = storage.getToken();

    
        const fetchUser = async () => {
            if (token) {
                const user = await check_user();
                dispatch(addUser(user));
                if (user) {
                    navigate(`/account/${user?.role}`);
                }
            }
        };

        useEffect(() => {
            fetchUser();
        }, [])
        
     
   
    useEffect(() => {
        dispatch(setPageTitle('Login'));
    }, [dispatch]);

    return (
        <div className="w-full h-[100vh] flex flex-col md:flex-row ">
            <div className="md:w-1/2 hidden md:block relative">
                <div className="flex flex-col w-full h-full absolute">
                    <img
                        src="https://live.staticflickr.com/65535/51151419994_ae939d5e9b_b.jpg"
                        className="w-full h-full object-cover"
                        alt=""
                    />
                </div>
            </div>
            <div className="md:w-1/2 w-full h-full p-8 flex flex-col justify-center items-center">
                <div className="w-[60%]">
                    <div className="flex flex-col items-start w-full">
                        <p className="text-[24px] font-[700]">Welcome Back!</p>
                        <p className="text-[16px] text-grayText">
                            Fill in your credentials
                        </p>
                    </div>
                    <Form<LoginInput, typeof schema>
                        schema={schema}
                        onSubmit={(payload: any) =>
                            handleLogin(payload)
                        }
                        className="mt-10"
                    >
                        {({ register, formState }) => (
                            <>
                                <div className="space-y-4">
                                    <InputField
                                        type="text"
                                        label="Email"
                                        placeholder="Your email"
                                        isLoading={loadingLogin}
                                        error={formState.errors.username}
                                        registration={register('username')}
                                        className="h-13"
                                    />

                                    <InputField
                                        type="password"
                                        label="Password"
                                        placeholder="********"
                                        isLoading={loadingLogin}
                                        error={formState.errors.password}
                                        registration={register('password')}
                                        className="h-13"
                                    />
                                    {error && (
                                        <p className="text-red-500 text-sm font-bold">
                                            {error}
                                        </p>
                                    )}
                                </div>

                                {loadingLogin ? (
                                    <button
                                        type="submit"
                                        className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                                    >
                                        <CircularProgress
                                            size={24}
                                            sx={{ color: 'white' }}
                                        />
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                                    >
                                        Log In
                                    </button>
                                )}
                            </>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Login;
