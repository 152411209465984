import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DataTableV2, { TableColumn } from '@/components/datatable/DataTable';
import formatDateToLongForm from '@/utils/DateFormattter';
import Filter from '@/components/filters/Filter';

import { capitalize } from '@mui/material';
import { useGetContributions } from '@/core/hooks/auth';

export const CardUSD: React.FC = () => {
    const contributionType = 1;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [currency, setCurrrency] = useState('USD');
    const [paymentMode, setPaymentMode] = useState('card');
    const [currentPage, setCurrentPage] = useState(1);
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;
    const { contributions, error, total, loading }: any = useGetContributions(
        pageSize,
        pageNumber,
        phoneNumber,
        contributionType,
        email,
        search,
        paymentMode,
        currency
    );

    const getStatusBadge = (status: string) => {
        let badgeClass = '';
        switch (status) {
            case 'pending':
                badgeClass = 'badge bg-warning';
                break;
            case 'success':
                badgeClass = 'badge bg-success';
                break;
            case 'failed':
                badgeClass = 'badge bg-danger';
                break;
            default:
                badgeClass = 'badge bg-secondary';
        }
        return <span className={badgeClass}>{status}</span>;
    };

    const columns: TableColumn<any>[] = [
        {
            title: 'Amount',
            accessor: 'amount',
            render: row => <p>{row.amount || ''}</p>,
        },
        {
            title: 'Currency',
            accessor: 'currency',
            render: row => <p>{row.currency || ''}</p>,
        },

        {
            title: 'Contributor Name',
            accessor: 'contributorName',
            render: row => <p>{capitalize(row.contributorName || '')}</p>,
        },
        {
            title: 'Phone Number',
            accessor: 'phoneNumber',
            render: row => <p>{row.phoneNumber || '-'}</p>,
        },
        {
            title: 'Email',
            accessor: 'email',
            render: row => <p>{row.email || '-'}</p>,
        },
        {
            title: 'Residence Country',
            accessor: 'residence_country',
            render: row => <p>{row.residence_Country || '-'}</p>,
        },

        {
            title: 'Date',
            accessor: 'createdAt',
            render: row => <p>{formatDateToLongForm(row?.createdAt)}</p>,
        },
        {
            title: 'Transaction Phone Number',
            accessor: 'transaction.phoneNumber',
            render: row => <p>{row?.transaction?.phoneNumber || '-'}</p>,
        },
        {
            title: 'Transaction Telco',
            accessor: 'transaction.telco',
            render: row => (
                <p className="capitalize">{row?.transaction?.telco || '-'}</p>
            ),
        },
        {
            title: 'Transaction Payment Mode',
            accessor: 'transaction.payment_mode',
            render: row => (
                <p className="capitalize">
                    {row?.transaction?.payment_mode || '-'}
                </p>
            ),
        },
        {
            title: 'Transaction Amount',
            accessor: 'transaction.amount',
            render: row => <p>{row?.transaction.amount || '-'}</p>,
        },
        {
            title: 'Transaction Status',
            accessor: 'transaction.status',
            render: (row: any) => getStatusBadge(row.transaction.status),
        },
    ];

    const handlePageChange = (page: number) => setCurrentPage(page);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="">
            <ol className="flex text-primary font-semibold dark:text-white-dark mb-2 ">
            <li className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                    <Link
                        to="/account/admin"
                        className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70"
                    >
                        Home
                    </Link>
                </li>
                <li className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                    <Link
                        to="/account/admin"
                        className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70"
                    >
                        Special contributions
                    </Link>
                </li>

                <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                    <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                         USD Card Transactions
                    </button>
                </li>
            </ol>
            
            <DataTableV2
                columns={columns}
                data={contributions?.list ?? []}
                total={contributions?.total ?? 0}
                currentPage={contributions?.currentPage ?? 0}
                filterComponent={
                 undefined
                }
                tableName="Card USD Transactions"
                nextPage={contributions?.nextPage ?? 0}
                previousPage={contributions?.previousPage ?? 0}
                lastPage={contributions?.lastPage ?? 0}
                isLoading={loading}
            />
        </div>
    );
};
