import Filter from '@/components/filters/Filter';
import { useGetCountryReport } from '@/core/hooks/stats';
import { IRootState } from '@/store';
import { countries } from '@/utils/constants';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';

export default function CountryReportsChart() {
    const { countryReport, fetchCountryReport } = useGetCountryReport();

    const [country, setCountry] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const resetFilters = () => {
        setCountry('');
        setFromDate('');
        setEndDate('');
    };

    useEffect(() => {
        fetchCountryReport(country,fromDate,endDate);
    }, [country,fromDate,endDate]);
    console.log('countryReport', countryReport);

    const isDark = useSelector(
        (state: IRootState) =>
            state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode
    );
    const isRtl = useSelector(
        (state: IRootState) => state.themeConfig.rtlClass === 'rtl'
    );
    const formatNumber = (num:string) => {
        return new Intl.NumberFormat('en-US').format(Number(num));
    };
    const capitalize = (str: string) => {
        return str
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };
    
    const barChart: any = {
        series: [
            {
                name: 'Total amount',
                data:
                    Array.isArray(countryReport) && countryReport.length > 0
                        ? countryReport.map((country: any) => {
                              const totalAmount = country.amount.reduce(
                                  (acc: number, item: any) => {
                                      if (item && item['RWF']) {
                                          acc += item['RWF'];
                                      }
                                      return acc;
                                  },
                                  0
                              );
                              return totalAmount;
                          })
                        : [],
            },
            {
                name: 'Total contributors',
                data: Array.isArray(countryReport)
                    ? countryReport.map((product: any) => product.count)
                    : [],
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 1,
            },
            colors: ['#4361ee', '#ff0000'],
            xaxis: {
                categories: Array.isArray(countryReport)
                    ? countryReport.map(
                          (country: any) =>
                              country?.residence_country
                                  ? capitalize(country.residence_country)
                                  : 'Other'
                      )
                    : [],
                axisBorder: {
                    color: isDark ? '#191e3a' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                reversed: isRtl ? true : false,
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            fill: {
                opacity: 0.8,
            },
            tooltip: {
                y: {
                    formatter: function (value: any) {
                        return new Intl.NumberFormat('en-US').format(value);
                    },
                },
            },
        },
    };
    
    return (
        <div className="panel">
            <p className='text-lg font-bold'>Country Report</p>
            <div className="flex  mt-4 flex-row justify-start">

            <Filter
                        title="Filter Country Reports"
                        resetFilters={resetFilters}
                    >
                     
                        <div className="flex flex-col">
                    <div className="mb-4">
                        <label htmlFor="countryCode">
                            Country name:
                        </label>
                        <select
                            id="countryCode"
                            value={country}
                            onChange={e => setCountry(e.target.value)}
                            className="form-select"
                        >
                            <option value="" disabled>Select a country</option>
                            {countries.map((country: any, index: number) => (
                                <option key={index} value={country.name.toLowerCase()}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    </div>
                            <div className="mb-4">
                                <label htmlFor="fromDate">From Date:</label>
                                <input
                                    type="date"
                                    id="fromDate"
                                    onChange={e => setFromDate(e.target.value)}
                                    className="form-input"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="endDate">End Date:</label>
                                <input
                                    type="date"
                                    id="endDate"
                                    onChange={e => setEndDate(e.target.value)}
                                    className="form-input"
                                />
                            </div>
                        </div>
                    </Filter>
                </div>
          
            {countryReport && countryReport !== null && (
                <ReactApexChart
                    series={barChart.series}
                    options={barChart.options}
                    className="rounded-lg bg-white dark:bg-black overflow-hidden"
                    type="bar"
                    height={300}
                />
            )}
        </div>
    );
}
