import { AuthLoginPayload, PaginationType } from "@/core/shared";
import { queryString } from "@/utils/query";
import { CustomError, AxiosErrorHandler } from "@/utils/request";
import PRIVATE_API, { PRIVATE_API_v2 } from "../axios";
import { UserDetails } from "@/core";


export type RegisterUserPayload = {
    access_token: string;
};

export type LoginUserPayload = {
    access_token: string;
};

export const register_user = async (
    memberPayload: UserDetails
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/user', memberPayload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};


export const admin_create_user = async (
    memberPayload: UserDetails
): Promise<any> => {
    try {
        const request = await PRIVATE_API_v2.post('/users', memberPayload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_user = async (payload : UserDetails, id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API_v2.patch(`/users/${id}`,payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const check_user = async (): Promise<UserDetails | any> => {
    try {
        const request = await PRIVATE_API_v2.get('/users/check');
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_user = async (id: string): Promise<UserDetails | any> => {
    try {
        const request = await PRIVATE_API_v2.delete(`/users/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const activate_user = async (activation_code:string): Promise<UserDetails | any> => {
    try {
        const request = await PRIVATE_API.post(`/user/activate/account/${activation_code}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
export const request_reset= async (payload:UserDetails): Promise<UserDetails | any> => {
    try {
        const request = await PRIVATE_API.post(`/user/password-reset/`,payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
export const Confirm_reset = async (payload:UserDetails): Promise<UserDetails | any> => {
    try {
        const request = await PRIVATE_API.post(`/user/confirm-password-reset-otp`,payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_users = async (query? : string): Promise<PaginationType<UserDetails>> => {
    try {
        const request = await PRIVATE_API_v2.get(`/users${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
export const get_applicants = async (query? : string): Promise<PaginationType<UserDetails>> => {
    try {
        const request = await PRIVATE_API.get(`/users/applicant${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
export const user_profile = async (): Promise<PaginationType<UserDetails>> => {
    try {
        const request = await PRIVATE_API.get(`/users/myProfile`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};



export const login_user = async (
    userCredentials: AuthLoginPayload
): Promise<LoginUserPayload | any> => {
    try {
        const request = await PRIVATE_API_v2.post('/users/login', userCredentials);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};