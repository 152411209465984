import DataTableV2, { TableColumn } from '@/components/datatable/DataTable';
import Filter from '@/components/filters/Filter';
import { DistrictReport } from '@/core';
import {
    useGetDistrictReport,
    useGetLocationsByLevelId,
} from '@/core/hooks/stats';
import { setPageTitle } from '@/store/themeConfigSlice';
import { set } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

export default function DistrictReports() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('District Reports'));
    }, [dispatch]);
    const [locationId, setLocationId] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const { loading, districtReport, fetchDistrictReport } =
        useGetDistrictReport();
    const { locations, fetchLocationsByLevelId }: any =
        useGetLocationsByLevelId();

    console.log(locations);
    const [page, setPage] = useState(1);
    const PAGE_SIZES = [10, 20, 30, 50, 100];

    useEffect(() => {
        fetchDistrictReport();
        fetchLocationsByLevelId();
    }, []);

    const columns: TableColumn<DistrictReport>[] = [
       
        {
            title: 'Location Name',
            accessor: 'locationName',
            render: row => <p>{row.locationName || ''}</p>,
        },
        {
            title: 'Total Amount',
            accessor: 'totalAmount',
            render: row => <p>{row.totalAmount || ''}</p>,
        },
        {
            title: 'Total contributors',
            accessor: 'count',
            render: row => <p>{row.count || ''}</p>,
        },
    ];

    const resetFilters = () => {
        setLocationId('');
        setFromDate('');
        setEndDate('');
    };

    useEffect(() => {
    
        fetchDistrictReport(locationId, fromDate, endDate);
    }, [ locationId, fromDate, endDate]);
    
    return (
        <div>
            <ol className="flex text-primary font-semibold dark:text-white-dark mb-2 ">
                <Link
                    to="/account/admin"
                    className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]"
                >
                    <button className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                        Home
                    </button>
                </Link>

                <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                    <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                        District Reports
                    </button>
                </li>
            </ol>
           
            {loading ? (
                <p>Loading...</p>
            ) : (
                <DataTableV2
                    columns={columns}
                    data={districtReport ?? []}
                    total={districtReport?.length ?? 0}
                    currentPage={page}
                    filterComponent={
                        <Filter
                            title="Filter district Reports"
                            resetFilters={resetFilters}
                        >
                            <div className="flex flex-col">
                                <div className="mb-4">
                                    <label htmlFor="countryCode">
                                    District:
                                    </label>
                                    <select
                                        id="countryCode"
                                        onChange={e =>
                                            setLocationId(e.target.value)
                                        }
                                        value={locationId}
                                        className="form-select"
                                    >
                                        <option value="">
                                            Select a district
                                        </option>
                                        {locations?.map((location: any) => (
                                            <option
                                                key={location.id}
                                                value={location.id}
                                            >
                                                {location.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="fromDate">From Date:</label>
                                    <input
                                        type="date"
                                        id="fromDate"
                                        onChange={e =>
                                            setFromDate(e.target.value)
                                        }
                                        className="form-input"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="endDate">End Date:</label>
                                    <input
                                        type="date"
                                        id="endDate"
                                        onChange={e =>
                                            setEndDate(e.target.value)
                                        }
                                        className="form-input"
                                    />
                                </div>
                            </div>
                        </Filter>
                    }
                    tableName="District Reports"
                    nextPage={0}
                    previousPage={0}
                    lastPage={1}
                    isLoading={loading}
                />
            )}
        </div>
    );
}
