import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CountryReportschart from '../stats/charts/country-chart';
import DistrictReportsChart from '../stats/charts/district-chart';
import { useGetStatsReport } from '@/core/hooks/stats';
import { FaDollarSign, FaCreditCard, FaMobileAlt, FaCheckCircle, FaUsers } from 'react-icons/fa';

export default function AdminDashboard() {
    const { fetchStatsReport, statsReport, loading }: any = useGetStatsReport();

    useEffect(() => {
        fetchStatsReport();
    }, []);

    const formatNumber = (number: any) => {
        return new Intl.NumberFormat().format(number ?? 0);
    };

    const totalCardUSD = statsReport?.transaction?.transactionsCard?.totalCardUSD ?? 0;
    const totalCardRWF = statsReport?.transaction?.transactionsCard?.totalCardRWF ?? 0;
    const transactionsMomo = statsReport?.transaction?.transactionsMomo ?? 0;
    const successTransactions = statsReport?.transaction?.success ?? 0;
    const totalMembers = 
        (statsReport?.member?.approvedMembers ?? 0) +
        (statsReport?.member?.pendingMembers ?? 0) +
        (statsReport?.member?.rejectedMembers ?? 0);

    return (
        <>
            <ol className="flex text-primary font-semibold dark:text-white-dark mb-4">
                <li className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                    <button className="p-2 ltr:pl-4 rtl:pr-4 ltr:pr-3 rtl:pl-3 relative h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                        Home
                    </button>
                </li>
                <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                    <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                        Special contributions(Since May 2024)
                    </button>
                </li>
            </ol>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
                <Link to="reports/card-USD" className="w-full">
                    <div className="bg-primary rounded-lg shadow-lg overflow-hidden">
                        <div className="px-6 py-8 flex items-center">
                            <FaCreditCard className="text-white text-3xl mr-4" />
                            <div>
                                <h2 className="text-lg font-semibold text-white mb-2">
                                    Total Card (USD)
                                </h2>
                                <p className="text-xl text-white font-bold">
                                    {formatNumber(totalCardUSD)}
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="reports/card-RWF" className="w-full">
                    <div className="bg-primary rounded-lg shadow-lg overflow-hidden">
                        <div className="px-6 py-8 flex items-center">
                            <FaCreditCard className="text-white text-3xl mr-4" />
                            <div>
                                <h2 className="text-lg font-semibold text-white mb-2">
                                    Total Card (RWF)
                                </h2>
                                <p className="text-xl text-white font-bold">
                                    {formatNumber(totalCardRWF)}
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="reports/momo" className="w-full">
                    <div className="bg-primary rounded-lg shadow-lg overflow-hidden">
                        <div className="px-4 py-8 flex items-center">
                            <FaMobileAlt className="text-white text-3xl mr-4" />
                            <div>
                                <h2 className="text-lg font-semibold text-white mb-2">
                                    Momo Transactions (RWF)
                                </h2>
                                <p className="text-xl text-white font-bold">
                                    {formatNumber(transactionsMomo)}
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="reports/successful" className="w-full">
                    <div className="bg-primary rounded-lg shadow-lg overflow-hidden">
                        <div className="px-6 py-8 flex items-center">
                            <FaCheckCircle className="text-white text-3xl mr-4" />
                            <div>
                                <h2 className="text-lg font-semibold text-white mb-2">
                                    Successful Transactions
                                </h2>
                                <p className="text-xl text-white font-bold">
                                    {formatNumber(successTransactions)}
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="members" className="w-full">
                    <div className="bg-primary rounded-lg shadow-lg overflow-hidden">
                        <div className="px-6 py-8 flex items-center">
                            <FaUsers className="text-white text-3xl mr-4" />
                            <div>
                                <h2 className="text-lg font-semibold text-white mb-2">
                                    Total Membership Requests
                                </h2>
                                <p className="text-xl text-white font-bold">
                                    {formatNumber(totalMembers)}
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-4">
                <DistrictReportsChart />
                <CountryReportschart />
            </div>
        </>
    );
}
