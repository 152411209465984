import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/store';
import Img from '@/assets/images/Screenshot 2024-05-21 234500.png';
import { setPageTitle } from '@/store/themeConfigSlice';
import { useDispatch } from 'react-redux';
import { InputField } from '@/components/input';
import { z } from 'zod';
import { Form } from '@/components/form';
import { EditUser } from '@/core/hooks/auth';

const schema = z.object({
    lastName: z.string().min(1, 'Firt name address required'),
    firstName: z.string().min(1, 'Last name  required'),
    email: z
    .string()
    .min(1, 'Email Name required')
    .refine((value) => value.trim() === value, {
        message: 'Email cannot have leading or trailing spaces',
    }),

    phoneNumber: z
        .string()
        .min(1, 'Phone Number is required')
        .max(10, 'Phone Number must be at most 10 characters')
        .min(10, 'Phone number are 10 numbers'),
});

export type UpdateuserInp = z.TypeOf<typeof schema>;

export const Settings = () => {
    const { userAccount:userData } :any= useAppSelector((store) => store.user);

console.log(userData)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Profile'));
    });
   const {hanldeEditUser,loadingEditUser,}= EditUser()

    return (
        <div className="">
            <div className="border border-[#ebedf2] dark:border-[#191e3a] rounded-md p-4 mb-5 bg-white dark:bg-black">
                <h6 className="text-lg font-bold mb-5">Account Information</h6>
                <div className="flex flex-col sm:flex-row">
                    <div className="ltr:sm:mr-4 rtl:sm:ml-4 w-full flex flex-col items-center sm:w-2/12 mb-5">
                        <img
                            src={Img}
                            alt="img"
                            className="w-20 h-20 md:w-32 md:h-32 rounded-full object-cover mx-auto"
                        />
                        <p>{userData?.name}</p>
                        <span className="  mx-2 badge bg-primary">
                            {' '}
                            {userData?.role}
                        </span>
                    </div>
                    <Form<UpdateuserInp, typeof schema>
                        schema={schema}
                        onSubmit={async (payload: any) => {
                            await hanldeEditUser (payload,userData?.id);
                        }}

                        className="mt-10    flex  justify-center  flex-col  w-full"
                    >
                        {({ register, formState }) => (
                            <>
                                <div className="gap-2 grid grid-cols-1 md:grid-cols-2">
                                    <InputField
                                        type="text"
                                        label="Full Name"
                                        placeholder="Ex. John "
                                        isLoading={loadingEditUser}
                                        defaultValue={userData?.name}

                                        error={formState.errors.firstName}
                                        registration={register('firstName')}
                                    />

                                    

                                    <InputField
                                        type="text"
                                        label="Email"
                                        defaultValue={userData?.email}
                                        placeholder="Email"
                                        isLoading={loadingEditUser}

                                        error={formState.errors.email}
                                        registration={register('email')}
                                    />
                                    <InputField
                                        type="text"
                                        label="Phone Number"
                                        defaultValue={userData?.phoneNumber}
                                        placeholder="Phone"
                                        isLoading={loadingEditUser}

                                        error={formState.errors.phoneNumber}
                                        registration={register('phoneNumber')}
                                    />
                                </div>
                                <div className="flex justify-end items-center mt-2">

                                </div>
                            </>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
};
