import {
    useGetDistrictReport,
    useGetLocationsByLevelId,
} from '@/core/hooks/stats';
import { IRootState } from '@/store';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import DistrictReports from '../district';
import Filter from '@/components/filters/Filter';

export default function DistrictReportsChart() {
    const { districtReport, fetchDistrictReport }: any = useGetDistrictReport();
    const [seriesData, setSeriesData] = useState([]);
    const [options, setOptions] = useState({});

    console.log('districtReport', districtReport);
    const isDark = useSelector(
        (state: IRootState) =>
            state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode
    );
    const isRtl = useSelector(
        (state: IRootState) => state.themeConfig.rtlClass === 'rtl'
    );
    const [locationId, setLocationId] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const resetFilters = () => {
        setLocationId('');
        setFromDate('');
        setEndDate('');
    };
    useEffect(() => {
        fetchDistrictReport();
        fetchLocationsByLevelId();
    }, []);

    useEffect(() => {
        fetchDistrictReport(locationId, fromDate, endDate);
    }, [locationId, fromDate, endDate]);

    const { locations, fetchLocationsByLevelId }: any =
        useGetLocationsByLevelId();

        const formatNumber = (num:string) => {
            return new Intl.NumberFormat('en-US').format(Number(num));
        };
    const barChart: any = {
        series: [
            {
                name: 'Total Amount (RWF)',
                data: Array.isArray(districtReport)
                    ? districtReport.map((product: any) => product.totalAmount)
                    : [],
            },
            {
                name: 'Total contributors',
                data: Array.isArray(districtReport)
                    ? districtReport.map((product: any) => product.count)
                    : [],
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 1,
            },
            colors: ['#4361ee', '#ff0000'], // Adding red color for the counts series
            xaxis: {
                categories: Array.isArray(districtReport)
                    ? districtReport.map((product: any) => product.locationName)
                    : [],
                axisBorder: {
                    color: isDark ? '#191e3a' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                reversed: isRtl ? true : false,
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            fill: {
                opacity: 0.8,
            },
            tooltip: {
                y: {
                    formatter: function (value:any){
                        return new Intl.NumberFormat('en-US').format(value);
                    },
                },
            },
        },
    };

    return (
        <div className="panel">
            <p className="text-lg font-bold">District Report Chart</p>
            <div className="flex  mt-4 flex-row justify-start">
                <Filter
                    title="Filter district Reports"
                    resetFilters={resetFilters}
                >
                    <div className="flex flex-col">
                        <div className="mb-4">
                            <label htmlFor="countryCode">District:</label>
                            <select
                                id="countryCode"
                                onChange={e => setLocationId(e.target.value)}
                                value={locationId}
                                className="form-select"
                            >
                                <option value="">Select a district</option>
                                {locations?.map((location: any) => (
                                    <option
                                        key={location.id}
                                        value={location.id}
                                    >
                                        {location.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="fromDate">From Date:</label>
                            <input
                                type="date"
                                id="fromDate"
                                onChange={e => setFromDate(e.target.value)}
                                className="form-input"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="endDate">End Date:</label>
                            <input
                                type="date"
                                id="endDate"
                                onChange={e => setEndDate(e.target.value)}
                                className="form-input"
                            />
                        </div>
                    </div>
                </Filter>
            </div>

            <ReactApexChart
                series={barChart.series}
                options={barChart.options}
                className="rounded-lg bg-white dark:bg-black overflow-hidden"
                type="bar"
                height={300}
            />
        </div>
    );
}
