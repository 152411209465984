import { setPageTitle } from '@/store/themeConfigSlice';
import { Tab } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Outlet, useLocation } from 'react-router-dom';

export default function UsersLayout() { 
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setPageTitle('Users'));
    }, [dispatch]);
 
    return (
        <>
            <div className="mt-5">
                <Outlet />
            </div>
        </>
    );
}
